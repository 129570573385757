var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "New__Entity" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "SideMenu__Content" },
      [
        _c("FieldInlineText", {
          attrs: {
            label: "לוחית רישוי",
            required: _vm.$v.model.PlateNumber.$anyError
          },
          model: {
            value: _vm.$v.model.PlateNumber.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.PlateNumber, "$model", $$v)
            },
            expression: "$v.model.PlateNumber.$model"
          }
        }),
        _c("FieldInlineSelect", {
          attrs: {
            label: "סוג",
            required: _vm.$v.model.VehicleType.$anyError,
            options: [
              { Value: 2, Text: "פרטי" },
              { Value: 1, Text: "מסחרי" }
            ],
            "null-is-value": "true"
          },
          model: {
            value: _vm.$v.model.VehicleType.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.VehicleType, "$model", $$v)
            },
            expression: "$v.model.VehicleType.$model"
          }
        }),
        _c("FieldInlineSelect", {
          attrs: {
            label: "יצרן",
            filterable: true,
            clearable: true,
            required: _vm.$v.model.Manufacturer.$anyError,
            optionsAsync: _vm.getManufacturers,
            "null-is-value": "true"
          },
          model: {
            value: _vm.$v.model.Manufacturer.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.Manufacturer, "$model", $$v)
            },
            expression: "$v.model.Manufacturer.$model"
          }
        }),
        _c("FieldInlineText", {
          attrs: { label: "דגם", required: _vm.$v.model.Model.$anyError },
          model: {
            value: _vm.$v.model.Model.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.Model, "$model", $$v)
            },
            expression: "$v.model.Model.$model"
          }
        }),
        _c("FieldInlineText", {
          attrs: { label: "שנה", required: _vm.$v.model.Year.$anyError },
          model: {
            value: _vm.$v.model.Year.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.Year, "$model", $$v)
            },
            expression: "$v.model.Year.$model"
          }
        }),
        _c("FieldInlineText", {
          attrs: {
            label: "צבע",
            required: _vm.$v.model.Color.$anyError,
            max: 10
          },
          model: {
            value: _vm.$v.model.Color.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.Color, "$model", $$v)
            },
            expression: "$v.model.Color.$model"
          }
        }),
        _c("FieldInlineDate", {
          attrs: { label: "תאריך התחלת בעלות" },
          model: {
            value: _vm.$v.model.Ownership.StartDate.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.Ownership.StartDate, "$model", $$v)
            },
            expression: "$v.model.Ownership.StartDate.$model"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "SideMenu__Footer" },
      [
        _c(
          "div",
          [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { disabled: _vm.isLoading, type: "" },
                on: { click: _vm.onCancel }
              },
              [_vm._v(" ביטול ")]
            ),
            _c(
              "b-button",
              {
                staticClass: "create",
                attrs: { loading: _vm.isLoading, type: "" },
                on: { click: _vm.onSave }
              },
              [_vm._v(" שמור ")]
            )
          ],
          1
        ),
        _c(
          "b-button",
          {
            staticClass: "close",
            attrs: {
              disabled: _vm.isLoading || !_vm.model.PlateNumber,
              loading: _vm.isLoading,
              type: ""
            },
            on: { click: _vm.fetchVehicleData }
          },
          [_vm._v(" משוך נתוני רכב ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "SideMenu__Header" }, [
      _c("h1", [_vm._v("הוספת רכב")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }